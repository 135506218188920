.battery {
  padding: 2px 2px;
  width: 300px;
  border: solid 8px rgba($blue-dim, 0.8);;
  border-radius: 8px;
  position: relative;
  background-color: $white;
  display: flex;
  align-items: center;

}

.bar {
  cursor: pointer;
  display: inline-block;
  width: 12px;
  margin: 1px;
  height: 25px;
  background: $gray;
  border-radius: 3px;
}

.bar.active {
  background: rgba($blue-dim, 0.8);
}

.progress-bar--container {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.progress-bar {
  height: 250px;
  width: 15px;
  border-radius: 16px;
  background-color: $white;
  position: relative;
  overflow: hidden;
}

.progress-bar--active {
  background-color: rgba($accent, 0.5);
  width: 15px;
  border-radius: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: height 0.3s ease-in-out;
}
