.forms__top {
  display: flex;
  width: 208px;
  height: 56px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 16px;
  background: rgba($white, 1);

  box-shadow: 0 0 40 0 rgba($white, 0.25);
  backdrop-filter: blur(40px);
  margin-bottom: 60px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
  color: $green-primary;

  @media screen and (max-width: 320px) {
   margin-bottom: 0;
  }

  &.forms__top--sleep-mode {
    background-color: $accent;
  }
}

.forms__top-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  align-items: center;
}

.forms__top-text {
  color: $green-primary !important;
  text-transform: uppercase;
  width: 40px;
  font-size: 12px;
  //&.forms__top-text--sleep-mode {
  //  color: #AAAAAA !important;
  //}
}

.forms__text-score {
  font-size: 24px;
  color: $black;
  text-transform: uppercase;
}

.forms_top__icon {
  width: 24px;
  height: 24px;
}

.forms__top-img {
  position: absolute;
  top: 18px;
  right: 18px;
}

.forms__middle {
  width: 300px;
  height: 139px;
  color: white;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 600;
  opacity: 0.5;
  visibility: hidden;
}
.forms__middle-container {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 150px;
  right: 0;
  z-index: 1000;
  animation: movement 2s ease infinite;
  -webkit-animation: movement 2s ease;
}


@keyframes movement {
  0% {
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    top: calc(97vh - 100px);
    left: 0;
  }
}

.forms__bottom {
  //width: 320px;
  width: 100vw;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  z-index: 999;

  .forms__bottom-big-card {
    height: 80px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;
    gap: 10px;
    background: linear-gradient(97deg, rgba(0, 0, 0, 0.00) 44.32%, #000 100%), rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(40px);
    margin-bottom: 5px;

    .forms__bottom-text-title {
      font-size: 60px;
      color: $white;
      height: 60px;

      @media screen and (max-width: 320px) {
        height: 30px;
        font-size: 30px;
      }
    }
    .forms__bottom-text {
      color: $accent;
      font-size: 30px;


      &.forms__bottom-text--blocked {
        color: $red !important;
      }
    }

  }

  .forms__bottom-inner-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    margin-bottom: 5px;
      
      @media all and (min-width: 356px) and (max-width: 768px){
          flex-wrap: nowrap;
      }
      
      @media all and (min-width: 769px){
          flex-wrap: nowrap;
      }
  }
  .forms__bottom-small-card {
    display: flex;
    padding: 10px 10px;
    align-items: center;
    gap: 10px;
    //flex: 0 0 33.3333%;
    width: 42%;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(40px);

    &.forms__bottom-small-card--small {
      width: 33%;
      @media screen and (max-width: 370px) {
        width: 100%;
      }
      @media screen and (max-width: 357px) {
        width: 100%;
      }
    }

    &.forms__bottom-small-card--sleep-mode {
      background-color: $red;
    }
    @media screen and (max-width: 780px) {
      width: 32%;
    }
    @media screen and (max-width: 370px) {
      width: auto;
    }
    @media screen and (max-width: 357px) {
      width: 100%;
    }
    .forms__bottom-result {
      font-size: 10px;
      color: $white;
    }
    .forms__bottom-result-title {
      font-size: 10px;
      color: rgba($white, 0.6);
    }
  }
}

.forms__disclaimer {
  color: rgba($white, 0.9);
  font-size: 10px;
  //width: 320px;
  width: calc(100vw - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  //position: absolute;
  //bottom: 10px;
  text-align: justify;
  @media screen and (max-width: 455px) {
    bottom: 0px;
  }
}

.card {
  //z-index: 1000;
  color: white;
  top: 130px;
  right: 0;
  position: absolute;
  width: 100px;
  font-size: 30px;
}

.breakline {
  display: none;
  //@media screen and (max-width: 400px) {
  //  display: block;
  //}
}


.forms__bottom-icon--sleep-mode {
  display: block;
}
.forms__bottom-icon--sleep-mode-blk {
  display: none;
}

.forms__bottom-icon--day-mode {
  display: block;
}
.forms__bottom-icon--day-mode-blk {
  display: none;
}
.forms__middle-image {
  display: flex;
  max-width: 50px;
  max-height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  //
  //@media screen and (max-width: 320px) {
  //  width: 55%;
  //  //margin-bottom: 140px;
  //}
}

.btn-for-copy-links {
    position: relative;
   -webkit-transition-duration: 0.4s; /* Safari */
   transition-duration: 0.4s;
   overflow: hidden;
   cursor: pointer;

  display: flex;
  //min-width: 320px;
  width: 100%;
  padding: 10px 20px;
  //border-radius: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: linear-gradient(85deg, #377B6D 46.3%, #6D9C63 100%);

  box-shadow: 0px 0px 20px 0px rgba(55, 123, 109, 0.60);

  color: $white;

  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-height: 55px;


  &.in-form {
    //padding: 16px 32px;
    //border-radius: 16px;
    padding: 10px 20px;
    border-radius: 10px;
    background: linear-gradient(85deg, #377B6D 46.3%, #6D9C63 100%);
    min-width: 169px;
    box-shadow: 0px 0px 20px 0px rgba(55, 123, 109, 0.60);
    flex: 3;
  }

  &.gray {
    background: #C3C3C3;
    pointer-events: none;
    box-shadow: none;
  }

  &.reverted {
    background: $white !important;
    color: $accent;
    border: 2px solid $blue-dim;
    min-width: 95px ;
    //padding: 16px 32px;
    //border-radius: 16px;
    padding: 10px 20px;
    border-radius: 10px;
    flex: 1;
  }
}
.btn-for-copy-links:after {
  content: "";
  background: rgba($accent, 0.6);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
  border-radius: 1000px;
}

.btn-for-copy-links:active:after {
  opacity: 1;
  transition: 0s
}

.earning-subtitle-text{
    width: 280px;
}

.gap-for-cards-titles{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 8px;
}

.picklezone-forms-wrapper{
    box-shadow: 0 4px 80px 0 rgba(0, 0, 0, 0.2509803922);
}

.picklezone-forms-container{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.gap-for-picklezone-block{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.picklezone-cnct-btn{
    display: flex;
    width: 100%;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    
    border-radius: 12px;
    background: $accent;
    
    @media all and (max-width: 768px){
     height: 45px;
    }
    
}

.rarity-common {
    display: flex;
    padding: 3px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    position: absolute;
    left: 9px;
    bottom: 16px;
    
    border-radius: 1000px;
    background: #848484;
    
    color: #FFF;
    text-align: center;
    font-family: "Roboto";
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

  &.rarity-common--in-modal {
    font-size: 14px;
  }
}

.rarity-rare {
    display: flex;
    padding: 3px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    position: absolute;
    left: 9px;
    bottom: 16px;
    
    border-radius: 1000px;
    background: #B1219A;
    
    color: #FFF;
    text-align: center;
    font-family: "Roboto";
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

  &.rarity-rare--in-modal {
    font-size: 14px;
  }
}
.animation-container1 {
  position: relative;
  //width: 100%;
  //height: 100vh;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  left: -200px;
}
