@import "src/styles/main";
@import "src/index.scss";
.telegramScreen {
    display: flex;
    flex-direction: column;
    width: 100vw;
    color: var(--tg-theme-text-color);
    overflow: hidden;
    padding: 0;
    margin: 0;
}






