.show-button {
  display: flex;
}
.hide-button {
  display: none;
}

.text-wrap {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button__connect {
  width: calc(100vw - 60px);
}
