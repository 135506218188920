$white: #ffffff;
$whiteSnow: #fdfdfd;
$whiteDim: #F5F5F5;
$black: #000000;
$bg-color: #000000;
$black-soft: #0B0B0D;
$accent:  #377B6D;
$accent-lighter: #0A8372;
$red: #FF4646;
$violet: #B479FF;
$violetLight: #B190B6;
$blue-dim:  #377B6D;
$accent-blue: #2977D4;
$light-green: #3DFD91;
$light-pink: #FF1694;
$light-blue: #8AEDFF;
$light-yellow: #FFFB81;
$lilac: #DA47FF;
$blue: #6AD2FF;
$gray: #989898;
$green-primary: #377B6D;
$green-accent: #0A8372;
