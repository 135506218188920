
:root {
  --dynamic-bg: url('../../assets/backgrounds/court_20.png');
}

.main-wrapper--inactive {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
}
.main-wrapper {
   background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
  background-image: var(--dynamic-bg);
  //background-image: url("../../assets/backgrounds/bgpattern1.png");
}
.main-wrapper--game {
  //background-image: url("../../assets/backgrounds/bgpattern3.png");
  //background-image: url("../../assets/backgrounds/bgpattern1.png");
  background-repeat: repeat;
  background-position: center;
  height:100vh;
  width: 100vw;

  &.main-wrapper--game--timeout {
    filter: grayscale(100%);
  }
}

.splash-wrapper {
  background-image:  linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../../assets/splash.png") ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
  z-index: 100 !important;
  background-color: lightgray;
  //background-position: 50%;
  //background-size: cover;
  //background-repeat: no-repeat;
}

.white-background{
    //background: linear-gradient(169deg, #EEF9FB 0%, #F9FDFE 46.5%, #EEF9FB 100%);
    //background-color: #fff;
}

.main-container {
  padding: 10px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  &.main-container-dim {
    padding: 20px;
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(6px);
  }

  .profile-img {
    width: 100vw;
    max-height: 70vh;
    background-size: cover;
  }

  .profile-text-container {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-form-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-text {
    color: $accent;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .profile-title {
    color: $black-soft;
    font-family: "Roboto", sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}


.picklezone-container {
  padding: 10px 27px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  &.picklezone-container-dim {
    padding: 20px;
    backdrop-filter: blur(10px);
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  }

  &.picklezone-container-max {
    padding: 0;
  }
}


.coin-card-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height:100vh;
    width:100vw;

  &.active {
    transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
  }
}



