// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* ----------------------------- SF Pro Display ----------------------------- */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/WorkSans-Medium.ttf");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/WorkSans-Bold.ttf");
}
@font-face {
  font-family: "Knewawe";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Knewave-Regular.ttf");
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/RobotoRegular.ttf");
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/RobotoMedium.ttf");
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/RobotoBold.ttf");
}

